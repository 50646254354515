import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

import { groupsConverter } from './group-request.converter';
import { environment } from '../../../environments/environment';
import {
  AddGroupMemberApiModel,
  AddRoomApiModel,
  GroupApiModel,
  GroupQueryParams,
  MemberGroupApiModel,
} from '../model/groups-api.model';
import { GroupModel, GroupViewModel } from '../model/groups.model';

@Injectable({
  providedIn: 'root',
})
export class GroupsApiService {
  readonly #http = inject(HttpClient);
  readonly #groupsUrl = environment.groupsApi;
  readonly #membersUrl = environment.membersApi;
  readonly #groupRoomsUrl = environment.groupRoomsApi;

  public getGroupById(groupId: string): Observable<GroupModel> {
    return this.#http.get<GroupModel>(`${this.#groupsUrl}${groupId}`);
  }

  public createGroup(group: GroupModel): Observable<GroupModel> {
    return this.#http.post<GroupModel>(this.#groupsUrl, group);
  }

  public updateGroup(group: GroupModel): Observable<GroupModel> {
    return this.#http.put<GroupModel>(
      `${this.#groupsUrl}${group.groupId}`,
      group
    );
  }

  public addRoomToGroup(room: AddRoomApiModel): Observable<GroupModel> {
    return this.#http.put<GroupModel>(`${this.#groupRoomsUrl}`, room);
  }

  public removeRoomFromGroup(room: AddRoomApiModel): Observable<GroupModel> {
    return this.#http.delete<GroupModel>(
      `${this.#groupRoomsUrl}remove/${room.roomDexId}/${room.groupId}`
    );
  }

  public addMemberToGroup(
    member: AddGroupMemberApiModel
  ): Observable<GroupModel> {
    return this.#http.put<GroupModel>(`${this.#membersUrl}`, member);
  }

  public removeMemberFromGroup(
    member: AddGroupMemberApiModel
  ): Observable<GroupModel> {
    return this.#http.delete<GroupModel>(
      `${this.#membersUrl}remove/${member.memberEmail}/${member.groupId}`
    );
  }

  public getMembersByName(
    groupName?: string
  ): Observable<MemberGroupApiModel[]> {
    const params = new HttpParams().set('queryParam', groupName ?? '');
    return this.#http.get<MemberGroupApiModel[]>(`${this.#membersUrl}`, {
      params: params,
    });
  }

  public getGroupsByQuery(
    queryParams: GroupQueryParams
  ): Observable<GroupViewModel[]> {
    const params = buildParams(queryParams);
    return this.#http
      .get<GroupApiModel[]>(this.#groupsUrl, { params })
      .pipe(map(groups => groupsConverter(groups)));
  }
}

export function buildParams(queryParams: object): HttpParams {
  let params = new HttpParams();
  Object.entries(queryParams)
    .filter((entry): entry is [string, string] => entry[1] !== undefined)
    .forEach(([key, value]) => {
      params = params.set(key, value);
    });
  return params;
}
