export interface BaseGroupApiModel {
  groupId: string;
  groupName: string;
  groupDescription: string;
  groupType: GroupTypeApi;
  memberEmails: string[];
}
export interface GroupApiModel extends BaseGroupApiModel {
  roomDexIds: string[];
}

export interface RoomHostsApiModel {
  roomDexId: string;
  hostEmails: string[];
}

export interface AddGroupMemberApiModel {
  groupId: string;
  memberEmail: string;
}

export interface HostRoomsApiModel {
  hostEmail: string;
  roomDexIds: string[];
}

export interface AddRoomApiModel {
  groupId: string;
  roomDexId: string;
}

export interface GroupQueryParams {
  memberEmail?: string;
  roomDexId?: string;
  groupName?: string;
  groupType?: GroupTypeApi;
}

export interface MemberGroupApiModel {
  memberEmail: string;
  groups: GroupMemberModel[];
}

export interface GroupMemberModel {
  groupId: string;
  groupType: GroupTypeApi;
  groupName: string;
}

export enum GroupTypeApi {
  HostGroup = 'HOST_GROUP',
  ServiceProvidersGroup = 'SERVICE_PROVIDERS_GROUP',
}
