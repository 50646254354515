import { RoomsModel } from '../../room-management/model/rooms.model';
import { GroupApiModel, GroupTypeApi } from '../model/groups-api.model';
import { GroupDetailedViewModel, GroupViewModel } from '../model/groups.model';

export function groupsConverter(groups: GroupApiModel[]): GroupViewModel[] {
  return groups.map(group => ({
    ...group,
    noOfRooms: group.roomDexIds?.length ?? 0,
    noOfUsers: group.memberEmails?.length ?? 0,
    groupType: groupTypeConverter(group.groupType),
  }));
}

export function groupConverter(group: GroupApiModel): GroupViewModel {
  return {
    ...group,
    noOfRooms: group.roomDexIds.length,
    noOfUsers: group.memberEmails.length,
    groupType: groupTypeConverter(group.groupType),
  };
}

export function groupDetailedViewConverter(
  group: GroupApiModel,
  rooms: RoomsModel[]
): GroupDetailedViewModel {
  return {
    ...group,
    rooms,
  };
}

export function groupTypeConverter(groupType: GroupTypeApi): {
  value: GroupTypeApi;
  text: string;
} {
  switch (groupType) {
    case GroupTypeApi.HostGroup:
      return {
        value: GroupTypeApi.HostGroup,
        text: 'Host',
      };
    case GroupTypeApi.ServiceProvidersGroup:
      return {
        value: GroupTypeApi.ServiceProvidersGroup,
        text: 'Service Provider',
      };
  }
}
