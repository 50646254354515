import { EnvironmentModel } from './environment.model';

const apiUrl = 'https://bokly-dev.aws.cloud.roche.com';

export const environment: EnvironmentModel = {
  development: true,
  apiUrl,
  authApi: `${apiUrl}/auth/v1/auth`,
  googleAuthUrl:
    'https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount',
  googleClientId:
    '1038809804201-f9ms8l26e90mnnpdgqn6m5ca58md1doq.apps.googleusercontent.com',
  roomDetailsApi: `${apiUrl}/rooms/v1/rooms/`,
  roomSettingsApi: `${apiUrl}/rooms/v1/managespaces/`,
  bookingDetailsApi: `${apiUrl}/roombookingadminapi/v1/roombookings/`,
  roomBookingsApi: `${apiUrl}/roombookingadminapi/v1/roombookings/host`,
  approveRoomBookingApi: `${apiUrl}/roombookingadminapi/v1/roombookings/approve/`,
  rejectRoomBookingApi: `${apiUrl}/roombookingadminapi/v1/roombookings/reject/`,
  cancelRoomBookingApi: `${apiUrl}/roombookingadminapi/v1/roombookings/cancel/`,
  chatMessages: `${apiUrl}/chat/v1/messages/`,
  roomsApi: `${apiUrl}/rooms/v1/rooms/items`,
  roomsByEmailApi: `${apiUrl}/membergroupsmanagement/v1/rooms/`,
  getRoomLayoutsApi: `${apiUrl}/roomlayoutmanagementapi/v1/rooms/`,
  roomLayoutsApi: `${apiUrl}/roomlayoutmanagementapi/v1/roomlayouts/`,
  roomAvailabilityApi: `${apiUrl}/freebusy/v1/unavailabilities/`,
  notificationsApi: `${apiUrl}/notifications/v1/notifications/`,
  groupsApi: `${apiUrl}/membergroupsmanagement/v1/groups/`,
  membersApi: `${apiUrl}/membergroupsmanagement/v1/members/`,
  groupRoomsApi: `${apiUrl}/membergroupsmanagement/v1/rooms/`,
  locationsApi: `${apiUrl}/rooms/v1/filterparams/locations`,
  roomsCalendarApi: `${apiUrl}/roombookingadminapi/v1/roombookings/host/calendar`,
};
